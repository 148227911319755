import {populateAtlasLegend} from "./shared/legend.js";

console.log("module website-map-page-base.js");

const
    gettext = window.gettext,
    pgettext = window.pgettext;


let spinnerValue = 0,
    hideSpinnerListeners = $j.Callbacks('unique');

export function addHideSpinnerListener(callback) {
    hideSpinnerListeners.add(callback);
}

export function removeHideSpinnerListener(callback) {
    hideSpinnerListeners.remove(callback);
}

export function showSpinner() {
    spinnerValue++;
    $j('#id_spinnerFrame').toggle(!!spinnerValue);
}

export function hideSpinner() {
    spinnerValue--;
    $j('#id_spinnerFrame').toggle(!!spinnerValue);
    if (spinnerValue < 0) {
        console.warn("SPINNER VALUE HAS GONE NEGATIVE!");
    } else if (!spinnerValue) {
        hideSpinnerListeners.fire();
    }
}

window.formatTimezone = function formatTimezone(utcOffset) {
    const
        h = Math.floor(Math.abs(utcOffset)),
        m = Math.floor(Math.abs(utcOffset) * 60) % 60;
    return pgettext("UTC timezone","GMT") +
           (utcOffset>=0 ? '+' : '-') +
           (h < 10 ? '0' : '') + h +
           ':' +
           (m < 10 ? '0' : '') + m;
};

export function pwInitAtlasJavaScriptBase() {
    console.log('Checking WebGL support exists');
    let gl, canvas = document.createElement('canvas');
    try { gl = canvas.getContext("webgl"); }
    catch (x) { gl = null; }
    if (gl == null) {
        try { gl = canvas.getContext("experimental-webgl");}
        catch (x) { gl = null; }
    }
    if (gl == null) {
        let browserMessage = gettext("Your browser does not have complete graphics feature support."),
            browserUrl;
        if (window.app && window.app.getSetting("systemName") === "ANDR") {
            window.alert(
                gettext("Sorry, but your Android device does not have graphics feature support for the forecast maps.")
            );
        } else if (/Windows/.test(navigator.userAgent)) {
            browserMessage += " " + gettext(
                "We recommend using Microsoft Edge or the Google Chrome browser to view this page."
            );
            browserMessage += " " + gettext(
                "Please click OK to proceed to the Microsoft browser download page."
            );
            browserUrl = "https://www.microsoft.com/edge";
        } else {
            browserMessage += " " + gettext(
                "We recommend using the latest version of Firefox or Chrome web browser to view this page."
            );
            browserMessage += " " + gettext(" Please click OK to proceed to the Google Chrome download page.");
            browserUrl = "https://www.google.com/chrome/";
        }
        if (browserUrl && window.confirm(browserMessage)) {
            window.location = browserUrl;
        }
        throw new Error("No WebGL support.");
    }
    console.log("Assigning global PWGMap object");
    window.PWGMap = new PWGMap_impl(PWGMap_options);

    [PWGMap.map1, PWGMap.map2].forEach(function(map, index) {
        if (!map) {
            return;
        }
        Atlas.properties(map, {
            gribSource: null, // model source for grib display
            hook_gribSource: function(prop, oldValue) {
                const map = this._atlasMap;
                if (map) {
                    map.removeGribTiles();
                    if (this.gribSource) {
                        map.redraw();
                    }
                }
            }
        });
    });
    console.log('Ensuring mapSourceChangedCallback');
    if (!PWGMap.mapSourceChangedCallback) {
        PWGMap.mapSourceChangedCallback = function (map, mapSource) {
            map.gribSource = PWGMap.getGribSourceForMapSource(mapSource, map.displayType);
        };
    }
    console.log('Ensuring getGribSourceForMapSource');
    if (!PWGMap.getGribSourceForMapSource) {
        PWGMap.getGribSourceForMapSource = function (mapSource) {
            let gribSource = mapSource,
                displayType = PWGMap.displayType;
            if (displayType === "Wave") {
                if (mapSource === "GFS") {
                    gribSource = "ECMWF";
                }
            }
            // FIXME: temporary hack while PWE/PWG do not have Gust or CAPE.
            if (displayType === "CAPE" || displayType === "Gust") {
                if (mapSource === "PWG") {
                    gribSource = "GFS";
                } else if (mapSource === "PWE") {
                    gribSource = "ECMWF";
                }
            }
            return gribSource;
        };
    }

    if (!(PWGMap instanceof HTMLElement)) {
        console.log('Installing Atlas hooks for PWGMap');
        // For the moment, using Atlas properties on LitElement objects is not workable.
        // Maybe move this code into website-routing.js or routing.js eventually?
        Atlas.properties(PWGMap, {
            displayType: null,
            hook_displayType: function (prop, oldValue) {
                if (prop.value) {
                    $j('.gribLegend').attr("data-display-type", PWGMap.displayType);
                    populateAtlasLegend(PWGMap.displayType);
                    PWGMap_addInitListener(function () {
                        PWGMap.setSymbol(PWGMap.getSavedSymbol());
                        [PWGMap.map1, PWGMap.map2].forEach(function (map) {
                            const atlasMap = map._atlasMap;
                            if (atlasMap) {
                                atlasMap.gribDisplayType = prop.value;
                                map.gribSource = PWGMap.getGribSourceForMapSource(map.mapSource, map.displayType);
                                if (map.gribSource) {
                                    // FIXME is this redraw now automatic?
                                    atlasMap.removeGribTiles();
                                    atlasMap.redraw();
                                }
                            }
                        });
                    });
                }
            }
        });
    }
    console.log('Adding setSymbol');
    PWGMap.setSymbol = function(symbol, displayType) {
        if (!symbol) {
            symbol = this.getSymbol(displayType);
            if (!symbol) return;
        }
        PWGMap_addInitListener(function() {
            const property = 'grib' + (displayType || PWGMap.displayType) + 'Symbol';
            $j.each([PWGMap.map1, PWGMap.map2], function() {
                if (this.isValid()) {
                    if (this._atlasMap[property] != symbol) {
                        this._atlasMap[property] = symbol;
                        // FIXME is this redraw now automatic?
                        this._atlasMap.removeGribTiles();
                        this._atlasMap.redraw();
                    }
                }
            });
        });
    };
    console.log('Adding getSavedSymbol');
    PWGMap.getSavedSymbol = function(displayType) {
        return (window.app ?
            app.getSetting("Maps_Grib" + (displayType || PWGMap.displayType) + 'Symbol')
            :
            window.pwStorage.getItem('MapsGrib' + (displayType || PWGMap.displayType) + 'Symbol')
        );
    };
    console.log('Adding getSymbol');
    PWGMap.getSymbol = function(displayType) {
        const property = 'grib' + (displayType || this.displayType) + 'Symbol';
        return this.map1._atlasMap && this.map1._atlasMap[property];
    };
    console.log('Adding setAndSaveSymbol');
    PWGMap.setAndSaveSymbol = function(symbol, displayType) {
        PWGMap.setSymbol(symbol, displayType);
        let setting;
        if (window.app) {
            setting = "Maps_Grib" + (displayType || PWGMap.displayType) + 'Symbol';
            app.setSetting(setting, symbol);
        } else {
            setting = 'MapsGrib' + (displayType || PWGMap.displayType) + 'Symbol';
            window.pwStorage.setItem(setting, symbol);
        }
    };
    console.log("Applying user map preferences");
    if (window.app) {
        app.setSetting("Maps_WindSymbol", null); // old setting
        app.setSetting("Maps_GribIsobarSymbol", null); // Buttons to change this went live, so revert it.
        app.addSettingsListener(function(upd) {
            PWGMap_addInitListener(function() {
                if (!PWGMap.map1._atlasMap) {
                    return;
                }
                [
                    "WaterLabels",
                    "CountryLabels",
                    "Places",
                    "HillShading",
                    "Buildings",
                    "Roads"
                ].forEach(function (s) {
                    const val = upd['VectorMap_' + s + 'Enabled'];
                    if (val !== undefined) {
                        PWGMap.map1._atlasMap.vectorMap[s].enabled = val;
                    }
                });
            });
        });
    } else {
        window.pwStorage.removeItem('mapWindSymbol'); // old setting
        window.pwStorage.removeItem("MapsGribIsobarSymbol"); // went live, but we don't want it.
        PWGMap_addInitListener(function() {
            const vectorMapPrefs = $j(document.body).data('vector-map-preferences') || {};
            Object.keys(vectorMapPrefs).forEach(function(key) {
                PWGMap.map1._atlasMap.vectorMap[key].enabled = vectorMapPrefs[key];
            });
        });
    }
    // Launch an ajax request to update user units
    // FIXME the units are probably already in the document, can we save a request?
    Atlas.updateUnitIndices();
}

